import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Product } from '../api/dataUtil';

interface SizeDropdownProps {
    setSizeSelected: (size: string) => void;
    // sizes: string[];
    product: Product;
}

export default function SizeDropdown({ setSizeSelected, product}: SizeDropdownProps) {

    const navigate = useNavigate();
    const sizes = product.sizes;

    // Set the default size to the first item in the sizes array
    useEffect(() => {
        if (sizes.length > 0) {
            setSizeSelected(sizes[0]);
        }
    }, [sizes, setSizeSelected]);

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSizeSelected(event.target.value);

        // Navigation logic based on specific conditions
        if (product.sku.substring(0, 2) === "BO") {
            switch (event.target.value) {
                case "Rustic Pines - 1oz.":
                    navigate('/product/BO-SC-01');
                    break;
                case "Midnight Bourbon - 1oz.":
                    navigate('/product/BO-SC-02');
                    break;
                case "Unscented - 1oz.":
                    navigate('/product/BO-NS-01');
                    break;
            }
        }
    };

    return (
        <div>
            <label htmlFor="size">Select: </label>
            <select id="size" name="size" className="size-dropdown" onChange={handleChange}>
                {sizes.map((size: string) => (
                    <option value={size} key={size}>{size}</option>
                ))}
            </select>
        </div>
    );
}
