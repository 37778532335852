import React, { useContext } from "react";
import Features from "./Features";
import Buy from "./Buy";
// import Spotlight from "./Spotlight";
import Aside from "./Aside";
import Hero from "./Hero";
import LogoTransition from "./LogoTransition";
import Testimonials from "./Testimonials";
import { ProductsContext } from "../api/DatabaseComponent";
// import FloatingShopButton from "../checkout/FloatingShopButton";

const Landing: React.FC = () => {
	//export default function Landing() {
	const products = useContext(ProductsContext);
	return (
		<>
			<Hero />
			<LogoTransition />
			<Buy products={products} sku='BO-SC-01' />
			<Features />
			<Testimonials />

			<section className='py-5'>
				<div className='container px-5 my-5'>
					<Aside />
				</div>
			</section>
			{/* <Spotlight /> */}
			{/* <FloatingShopButton startingState={0.75} targetOpacity={.75} scrollTrigger={100} visibilityTrigger={500} /> */}
		</>
	);
}

export default Landing;