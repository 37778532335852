import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Provider } from 'react-redux';
import { loadStripe } from "@stripe/stripe-js";
import { Route, Routes, useLocation } from "react-router-dom";
import { stripeSecret } from './components/api/dataUtil';
import { updatePageTitle } from './utilities/titleHelper';
import "./styles/App.css";
import About from "./components/About";
import Cart from './components/store/Cart';
import Checkout from './components/checkout/Checkout';
import Contact from './components/Contact';
import Footer from "./components/Footer";
import Landing from "./components/landing/Landing";
import Nav from "./components/navigation/Nav";
import Privacy from "./components/privacyterms/Privacy";
import ProductPage from './components/store/ProductPage';
import Return from './components/checkout/Return';
import ScrollToTop from './components/ScrollToTop';
import Shop from "./components/store/Shop";
import Terms from "./components/privacyterms/Terms";
import store from './components/store/Store';
import DatabaseComponent from './components/api/DatabaseComponent';
import LoadingScreen from './components/LoadingScreen';

// Load Stripe outside of a component's render to avoid recreating the Stripe object on every render
const stripePromise = loadStripe(stripeSecret);

// Initializes ReactGA-4 for Google Analytics
ReactGA.initialize('G-EKGR6GXP7B');
ReactGA.event('session_start');

function App() {
  const location = useLocation();
  const [isPageReady, setIsPageReady] = useState(true);

  // Handle initial page load and route changes
  useEffect(() => {
    setIsPageReady(false); // Show loading screen on route change

    const timer = setTimeout(() => {
      setIsPageReady(true); // Hide loading screen after 1 second
    }, 1000);

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, [location.pathname]); // Run whenever the route changes

  // Track page views with Google Analytics
  useEffect(() => {
    const pathname = location.pathname;
    updatePageTitle(pathname);
    ReactGA.send({ hitType: "pageview", page: pathname });
  }, [location.pathname]);

  return (
    <Provider store={store}>
      <DatabaseComponent>
        {!isPageReady && <LoadingScreen />} {/* Show loading screen when isPageReady is false */}
        <div className={`app-container ${isPageReady ? 'visible' : 'hidden'}`}>
          <ScrollToTop />
          <Nav />
          <Routes>
            <Route path='/about' element={<About />} />
            <Route path='/cart' element={<Cart />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/product/:sku' element={<ProductPage />} />
            <Route path='/' element={<Landing />} />
            <Route path='/privacy' element={<Privacy />} />
            <Route path='/shop' element={<Shop />} />
            <Route path='/terms' element={<Terms />} />
            <Route path='/checkout' element={<Checkout stripePromise={stripePromise} />} />
            <Route path='/return' element={<Return />} />
          </Routes>
          <Footer />
        </div>
      </DatabaseComponent>
    </Provider>
  );
}

export default App;
