import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { ProductsContext } from '../api/DatabaseComponent';

const Shop: React.FC = () => {

  const products = useContext(ProductsContext);

  return (
    <section className="py-5">
      <div className="container px-4 px-lg-5 mt-5">
        <div className="row gx-4 gx-lg-5 row-cols-2 row-cols-md-3 row-cols-xl-4 justify-content-center mb-5">
          <h1 className="fw-bolder">Shop</h1>
        </div>
        <div className="row gx-4 gx-lg-5 row-cols-2 row-cols-md-3 row-cols-xl-4 justify-content-center">

          {products.map((product: any) => (
            <>
              {product.include && (
                <div className="col mb-5" id={product.guid}>
                  <div className="card h-100">
                    {/* <!-- Product image--> */}
                    <Link to={"/product/" + product.sku}>
                      <img
                        className="card-img-top"
                        src={product.images[0]}
                        alt="..."
                        style={product.inventory === 0 ? { opacity: 0.5, filter: 'grayscale(50%)' } : {}}
                      />
                    </Link>
                    {/* <!-- Product details--> */}
                    <div className="card-body p-4">
                      <div className="text-center">
                        {/* <!-- Product name--> */}
                        <h5 className="fw-bolder">{product.product_type}</h5>
                        <p className="text-muted">{product.product_name}</p>
                        {/* <!-- Product price--> */}
                        ${product.product_price}
                      </div>
                    </div>
                    {/* <!-- Product actions--> */}
                    <div className="card-footer p-4 pt-0 border-top-0 bg-transparent">
                      <div className="text-center"><Link className="btn btn-outline-dark mt-auto" to={"/product/" + product.sku}>View Item</Link></div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}


        </div>
      </div>
    </section>
  )
}

export default Shop;
