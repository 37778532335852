import React, { useState, useEffect } from "react";
import ReactGA from 'react-ga4';
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "./PaymentForm";
import { Stripe } from "@stripe/stripe-js";
import AddressForm from "./AddressForm";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/Store";
import { serverURL, shippingId } from "../api/dataUtil";
import ShippingRateForm from "./ShippingRateForm";
import LinkAuthenticationForm from "./LinkAuthenticationForm";
import CheckoutSummary from "./CheckoutSummary";
import { setStateCode } from "../store/CartSlice";

interface CheckoutProps {
  stripePromise: Promise<Stripe | null>;
}

const Checkout: React.FC<CheckoutProps> = ({ stripePromise }) => {

  // From database
  const fetchURL = serverURL + "create-payment-intent";

  // local variables
  const [state, setState] = useState<string>("");
  const [salesTax, setSalesTax] = useState<number>(0);
  const [total, setTotal] = useState<number | 0>(0);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [inProgress, setInProgress] = useState<boolean>(false);

  // Redux variables
  // const clientSecret = useSelector((state: RootState) => state.cart.clientSecret);
  const stateCode = useSelector((state: RootState) => state.cart.stateCode);
  const cart = useSelector((state: RootState) => state.cart.cart);
  const discount = useSelector((state: RootState) => state.cart.discount);
  const discountId = discount.code;
  const dispatch = useDispatch();

  const appearance = {
    theme: 'stripe' as const,
  };

  const createPaymentIntent = async () => {
    setInProgress(true);
    const items = cart.map((item) => ({
      id: item.price_id,
      quantity: item.quantity || 1,
      size: item.selected_size,
    }));

    try {
      const response = await fetch(fetchURL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          items,
          shippingId,
          discountId: discountId || undefined,
          salestax: salesTax,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to create PaymentIntent");
      }
      const data = await response.json();

      // dispatch(setClientSecret(data.clientSecret));
      setClientSecret(data.clientSecret);
    } catch (error) {
      console.error("Error creating PaymentIntent:", error);
    } finally {

      setTimeout(() => {
        setInProgress(false);
      }, 3000);
    }
  };

  const trackCheckoutStarted = () => {
    ReactGA.event('begin_checkout');
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!inProgress) {
      if (!clientSecret) {
        createPaymentIntent();
      } else if (clientSecret && state !== "" && state !== stateCode) {
        createPaymentIntent();
        dispatch(setStateCode(state));
      }
    }
  }, [state]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    trackCheckoutStarted();
  }, [clientSecret]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <section className=''>
      <div className='container px-5 my-5'>
        <div className="row gx-5 d-flex col-xl-9 col-lg-10 mx-auto justify-content-center">
          {clientSecret ? (
            <div className="card" style={{ padding: '3rem !important' }}>
              <Elements options={{ clientSecret, appearance }} stripe={stripePromise}>
                <LinkAuthenticationForm />
                <AddressForm setStateCode={setState} setSalesTax={setSalesTax} />
                <ShippingRateForm />
                <CheckoutSummary total={total} setTotal={setTotal} clientSecret={clientSecret} stateCode={stateCode} salesTax={salesTax} />
                {stateCode && (
                  <PaymentForm clientSecret={clientSecret} total={total} stateCode={stateCode} discountId={discountId} />
                )}
              </Elements>
            </div>
          ) : (
            <div
              className='spinner'
              id='spinner'></div> // Optionally display a loading state
          )}
        </div>
      </div>
    </section>
  );
}

export default Checkout;
