import React, { useState } from 'react'
import { serverURL } from '../api/dataUtil';
import { AppDispatch } from './Store';
import { addDiscountAmount, addDiscountCode, removeDiscountAmount, removeDiscountCode } from './CartSlice';

interface DiscountProps {
    dispatch: AppDispatch
}

export default function Discount({ dispatch }: DiscountProps) {

    const [discountCode, setDiscountCode] = useState('');
    const fetchURL = serverURL + 'api/discount/validate-discount';

    const remove = () => {
        dispatch(removeDiscountCode());
        dispatch(removeDiscountAmount());
        setDiscountCode('');
    }

    const handleSubmit = async () => {

        try {
            const response = await fetch(fetchURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ discountCode }),
            });

            const result = await response.json();
            if (result.valid) {
                dispatch(addDiscountCode(result.discount.coupon.id));
                // window.alert(result.discount.coupon.amount_off + " " + result.discount.percent_off);
                if (result.discount.coupon.amount_off) {
                    dispatch(addDiscountAmount(result.discount.coupon.amount_off/100));
                } else {
                    dispatch(addDiscountAmount(result.discount.coupon.percent_off));
                }
                setDiscountCode('');
                window.alert("Discount added!");
            } else {
                window.alert("Discount not valid");
                remove();
            }
        } catch (error) {
            console.error('Error validating discount code:');
        }
    }

    return (
        <div className='discount-input-group mb-3'>
            <input type='text'
                placeholder='Discount Code'
                value={discountCode}
                className=''
                onChange={(e) => setDiscountCode(e.target.value)}
            />
            <button className="btn btn-outline-dark px-4 me-sm-3" onClick={() => handleSubmit()}>Apply</button>
        </div>
    )
}
