import React, { useEffect, useState, createContext, ReactNode } from 'react';
import { Product, serverURL } from './dataUtil';
import { getImagesByPath, getBuyImageByPath } from './imageHelper';
import { generateGUID } from "../store/CartSlice";

// Define context to provide products to child components
export const ProductsContext = createContext<Product[]>([]);

interface DatabaseComponentProps {
    children: ReactNode;
}

const DatabaseComponent: React.FC<DatabaseComponentProps> = ({ children }) => {
    const [products, setProducts] = useState<Product[]>([]); // Use state to track products

    useEffect(() => {
        const loadProducts = async () => {
            const fetchedProducts = await fetchProductsList(); // Await the transformed products
            // console.log('Fetched products:', fetchedProducts); // Log fetched products
            setProducts(fetchedProducts); // Update state with the processed products
        };
        loadProducts();
    }, []);

    // Function to fetch active inventory from the backend and transform data
    const fetchProductsList = async (): Promise<Product[]> => {
        try {
            const response = await fetch(`${serverURL}api/products/get-active-inventory`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch products: ${response.statusText}`);
            }

            const data = await response.json();

            // console.log('Data fetched:', data);

            // Transform the fetched data to match the Product interface
            const processedProducts: Product[] = data.map((item: any) => ({
                product_id: item.productId,
                product_type: item.productType,
                product_name: item.productName,
                product_price: item.price,
                images: getImagesByPath(item.imagesPath), // Will be populated based on images_path below
                buy_image: getBuyImageByPath(item.buyImage),
                guid: generateGUID(), // Generate a new GUID for each product
                sku: item.sku,
                price_id: item.priceId,
                description: item.description,
                ingredients: item.ingredients.split(','),
                key_ingredients: item.keyIngredients.length > 1 ? item.keyIngredients.split(',') : "",
                handcrafted: item.handcrafted,
                how_to: item.howTo,
                sizes: item.sizes,
                selected_size: item.selectedSize,
                category: item.category,
                include: item.include,
                images_path: item.imagesPath,
                inventory: item.inventory,
                msrp: item.msrp
            }));

            // console.log('Products fetched and processed successfully:', processedProducts);
            return processedProducts;
        } catch (error) {
            console.error('Error fetching products:', error);
            return []; // Return an empty array on error
        }
    };
    
    return (
        <ProductsContext.Provider value={products}>
            {children}
        </ProductsContext.Provider>
    );
};

export default DatabaseComponent;
