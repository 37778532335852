import React, { useContext, useEffect, useState } from 'react'
import ReactGA from 'react-ga4';
import { useDispatch } from 'react-redux';
import { addToCart } from './CartSlice';
import { getProductBySku, defaultProduct } from '../api/dataUtil';
import { generateGUID } from './CartSlice';
import { useParams, Link, useNavigate } from 'react-router-dom';
import KeyIngredients from '../landing/KeyIngredients';
import SizeDropdown from './sizedropdown';
import { ProductsContext } from '../api/DatabaseComponent';

const ProductPage: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const products = useContext(ProductsContext);

    const { sku } = useParams();
    const product = getProductBySku(products, sku || 'default') || defaultProduct;
    const [sizeSelected, setSizeSelected] = useState<string>('');

    const trackProductView = () => {
        ReactGA.event('view_item', {
            items: [
                {
                    item_id: product.product_id,
                    item_name: product.product_name
                }
            ]
        });
    }

    const addProductToCart = () => {
        ReactGA.event('add_to_cart', {
            items: [
                {
                    item_id: product.product_id,
                    item_name: product.product_name
                }
            ]
        })
    }

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        trackProductView();
    }, [product]);
    /* eslint-enable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (sizeSelected === "Unscented" && sku === "BO-SC-01") {
            navigate('/product/BO-NS-01');
        } else if (sizeSelected === "Rustic Pines" && sku === "BO-NS-01") {
            navigate('/product/BO-SC-01');
        }
    }, [sizeSelected, navigate, sku]);

    const addToCartHandler = () => {
        const newProduct = {
            buy_image: product.buy_image,
            category: product.category,
            description: product.description,
            guid: generateGUID(),
            handcrafted: product.handcrafted,
            how_to: product.how_to,
            product_id: product.product_id,
            images: product.images,
            include: product.include,
            ingredients: product.ingredients,
            key_ingredients: product.key_ingredients,
            product_type: product.product_type,
            product_name: product.product_name,
            product_price: product.product_price,
            price_id: product.price_id,
            selected_size: product.selected_size,
            sizes: product.sizes,
            sku: product.sku,
            quantity: 1,
            images_path: product.images_path,
            inventory: product.inventory,
            msrp: product.msrp
        }
        dispatch(addToCart(newProduct));
        addProductToCart();
        window.alert("Item added to cart!");
    };

    return (
        <section className="py-5">
            <div className="container px-4 px-lg-5 my-5 mb-2">
                <Link
                    className=""
                    to="/shop"
                >
                    &#9664; Continue Shopping
                </Link>

                <div className="row gx-4 gx-lg-5 align-items-center">

                    <div className="col-md-6">

                        {product.images.length > 1 ? (
                            <div id="productCarousel" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-inner">
                                    {product.images.map((image, index) => (
                                        <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                                            <img className="d-block w-100" src={image} alt={`Product ${index + 1}`} />
                                        </div>
                                    ))}
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#productCarousel" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#productCarousel" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        ) : (
                            <img className="card-img-top mb-5 mb-md-0" src={product.images[0]} alt="Product" />
                        )}

                    </div>
                    <div className="col-md-6">
                        <div className="small mb-1">SKU: {product.sku}</div>
                        <h1 className="display-5 fw-bolder">{product.product_type}</h1>
                        <h3 className="section-subheading text-muted"><i>{product.product_name}</i></h3>
                        <div className="fs-5 mb-3">
                            {product.msrp > product.product_price && (
                                <span className="text-decoration-line-through text-muted">${product.msrp}</span>
                            )}
                            <span> ${product.product_price}</span>
                            <span className="text-primary small"> + Free shipping</span>
                        </div>
                        <div className="mb-1">
                            {product.sizes.length > 1 && (
                                <div className="d-flex mb-3">
                                    <SizeDropdown setSizeSelected={setSizeSelected} product={product} />
                                </div>
                            )}
                        </div>
                        {product.inventory !== 0 ? (
                            <>
                                <div className="d-flex mb-3">
                                    <button className="btn btn-outline-dark flex-shrink-0" type="button" onClick={addToCartHandler}>
                                        <i className="bi-cart-fill me-1"></i>
                                        Add to cart
                                    </button>
                                </div>
                                <div className="d-flex mb-3">
                                    {product.inventory < 6 && (
                                        <span>Limited stock remaining!</span>
                                    )}
                                </div>
                            </>
                        ) : (
                            <div className="d-flex mb-3">
                                <h2 className="fw-bolder">SOLD OUT</h2>
                            </div>
                        )}

                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Description
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        {product.description}
                                        {product.handcrafted && (
                                            <p className="fw-bolder">All our grooming products are individually handcrafted from an all-natural, Non-GMO, pretrolatum and paraben free formula.</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {product.handcrafted ? (
                                <>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                Full Ingredients
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <ul>
                                                    {product.ingredients.map((ingredient: String) => (
                                                        <li>{ingredient}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                How To Use
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                {product.how_to}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                Additional Info
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <ul>
                                                    {product.ingredients.map((ingredient: String) => (
                                                        <li>{ingredient}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>


                    </div>
                </div>
            </div>
            {product.key_ingredients.length > 1 && (
                <KeyIngredients keys={product.key_ingredients} />
            )}

        </section>
    )
}

export default ProductPage;
