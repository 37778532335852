import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Product } from '../api/dataUtil';

interface CartState {
  cart: Product[];
  discount: {
    code: string;
    amount: number;
  };
  clientSecret: string | null;
  stateCode: string | null;
}

const initialState: CartState = {
  cart: JSON.parse(localStorage.getItem('cart') || '[]'), // Load cart from localStorage
  discount: JSON.parse(localStorage.getItem('discount') || '{"code": "", "amount": 0}'), // Load discount from localStorage
  clientSecret: null,
  stateCode: null,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart(state, action: PayloadAction<Product>) {
      if (!action.payload.guid) {
        action.payload.guid = generateGUID();
      }
      state.cart.push(action.payload);
      localStorage.setItem('cart', JSON.stringify(state.cart)); // Save cart to localStorage
    },
    removeFromCart(state, action: PayloadAction<string>) {
      state.cart = state.cart.filter(item => item.guid !== action.payload);
      localStorage.setItem('cart', JSON.stringify(state.cart)); // Save cart to localStorage
    },
    clearCart(state) {
      state.cart = [];
      localStorage.setItem('cart', JSON.stringify(state.cart)); // Save cart to localStorage
      state.discount.code = '';
      localStorage.setItem('discount', JSON.stringify(state.discount));
      state.discount.amount = 0;
      localStorage.setItem('discount', JSON.stringify(state.discount));
    },
    addDiscountCode(state, action: PayloadAction<string>) {
      console.log(action.payload);
      state.discount.code = action.payload;
      localStorage.setItem('discount', JSON.stringify(state.discount)); // Save discount to localStorage
    },
    removeDiscountCode(state) {
      state.discount.code = '';
      localStorage.setItem('discount', JSON.stringify(state.discount)); // Save discount to localStorage
    },
    addDiscountAmount(state, action: PayloadAction<number>) {
      state.discount.amount = action.payload;
      localStorage.setItem('discount', JSON.stringify(state.discount)); // Save discount to localStorage
    },
    removeDiscountAmount(state) {
      state.discount.amount = 0;
      localStorage.setItem('discount', JSON.stringify(state.discount)); // Save discount to localStorage
    },
    clearDiscount(state) {
      state.discount = { code: '', amount: 0 };
      localStorage.setItem('discount', JSON.stringify(state.discount)); // Save discount to localStorage
    },
    setClientSecret: (state, action: PayloadAction<string | null>) => {
      state.clientSecret = action.payload;
    },
    resetPaymentSession: (state) => {
      state.clientSecret = null;
    },
    setStateCode(state, action: PayloadAction<string>) {
      state.stateCode = action.payload;
    },
    resetStateCode(state) {
      state.stateCode = null;
    },
  },
});

export function generateGUID(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const {
  addToCart,
  removeFromCart,
  clearCart,
  addDiscountCode,
  removeDiscountCode,
  addDiscountAmount,
  removeDiscountAmount,
  clearDiscount,
  setClientSecret,
  resetPaymentSession,
  setStateCode,
  resetStateCode
} = cartSlice.actions;
export default cartSlice.reducer;
