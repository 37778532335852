import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from './Store';
import { clearCart, removeFromCart } from './CartSlice';
import { Link } from 'react-router-dom';
import Discount from "./Discount";

const Cart: React.FC = () => {
  const cart = useSelector((state: RootState) => state.cart.cart);
  const discount = 0 + useSelector((state: RootState) => state.cart.discount.amount);
  

  const dispatch: AppDispatch = useDispatch();

  const handleRemove = (guid: string) => {
    dispatch(removeFromCart(guid));
  };

  const handleClearCart = () => {
    dispatch(clearCart());
  }

  const cartTotal = cart.reduce((sum, item) => {
    return sum + item.product_price;
  }, 0);

  return (
    <section className="h-100 gradient-custom">
      {cart.length > 0 ? (
        <div className="container py-5">
          <div className="row d-flex justify-content-center my-4">
            <Link
              className=""
              to="/shop"
            >
              &#9664; Continue Shopping
            </Link>
            <div className="col-md-8">

              <div className="card mb-4">

                <div className="card-header py-3 secondary-background text-white">
                  <h5 className="mb-0">Cart - {cart.length} {cart.length > 1 ? 'items' : 'item'}</h5>
                </div>
                <div className="card-body">
                  {cart.map((item, index) => (
                    <div className="row" key={item.guid}>
                      <div className="col-lg-3 col-md-12 mb-4 mb-lg-0">
                        <div className="bg-image hover-overlay hover-zoom ripple rounded" data-mdb-ripple-color="light">
                          <img src={item.images[0]} className="w-100" alt={item.product_name} />
                        </div>
                      </div>
                      <div className="col-lg-5 col-md-6 mb-4 mb-lg-0">
                        <p><strong>{item.product_type}</strong></p>
                        <p className="text-muted">{item.product_name}
                          {!item.handcrafted && (
                            <>, {item.selected_size}</>
                          )}
                        </p>
                        <button
                          type="button"
                          className="btn btn-danger btn-sm mb-2"
                          onClick={() => handleRemove(item.guid!)}
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                      </div>
                      <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                        <p className="text-start text-md-center">
                          <strong>${item.product_price}</strong>
                        </p>
                      </div>
                      {index + 1 !== cart.length && <hr className="my-4" />}
                    </div>
                  ))}

                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mb-4">
                <div className="card-header py-3 secondary-background">
                  <h5 className="mb-0 text-white">Summary</h5>
                </div>
                <div className="card-body">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between align-items-center px-0 border-0">
                      Discount
                      <span className=''>{discount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0">
                      <div>
                        <strong>Subtotal</strong>
                      </div>
                      <span><strong>{(cartTotal - discount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</strong></span>

                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 text-muted">
                      <p className='small'>Free shipping. Applicable taxes calculated at checkout.</p>
                    </li>
                    <li className="list-group-item d-flex justify-content-center align-items-center border-0 px-0">
                      <Discount dispatch={dispatch} />
                    </li>
                  </ul>
                  <Link className="btn btn-primary btn-lg px-4 me-sm-3" to="/checkout">
                    Checkout
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <Link onClick={() => handleClearCart()} to="#" className="small" style={{ color: 'red' }}>Clear Cart</Link>
        </div>
      ) : (
        <div className="container">
          <div className="about-heading-content">
            <div className="row">
              <div className="col-xl-9 col-lg-10 mx-auto">
                <div className="bg-white rounded p-5 text-center">
                  <p>Your cart is currently empty. <Link
                    className=""
                    to="/shop"
                  >
                    Continue Shopping
                  </Link></p>

                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Cart;
