import rusticPinesImage from "../../images/products/buy_images/rustic-pines.jpg";

// Helper function to import all images
const importAll = (requireContext: __WebpackModuleApi.RequireContext) => requireContext.keys().map(requireContext);

// Preload images for specific paths
const apht01Images = importAll(require.context('../../images/products/apht01', false, /\.(png|jpe?g|svg)$/));
const apts02Images = importAll(require.context('../../images/products/apts02', false, /\.(png|jpe?g|svg)$/));
const apts03Images = importAll(require.context('../../images/products/apts03', false, /\.(png|jpe?g|svg)$/));
const oneOzImages = importAll(require.context('../../images/products/oneOz', false, /\.(png|jpe?g|svg)$/));

// Function to get images based on images_path
export const getImagesByPath = (imagesPath: string): string[] => {
    switch (imagesPath) {
        case 'products/apht01':
            return apht01Images;
        case 'products/apts02':
            return apts02Images;
        case 'products/apts03':
            return apts03Images;
        case 'products/oneOz':
            return oneOzImages;
        default:
            return ['https://dummyimage.com/600x600/dee2e6/6c757d.jpg']; // Return an empty array if no match is found
    }
};

export const getBuyImageByPath = (imagesPath: string): string => {
    switch (imagesPath) {
        case 'products/apht01':
            return apht01Images[0];
        case 'products/apts02':
            return apts02Images[0];
        case 'products/apts03':
            return apts03Images[0];
        case 'products/oneOz':
            return rusticPinesImage;
        default:
            return 'https://dummyimage.com/600x600/dee2e6/6c757d.jpg'; // Return an empty array if no match is found
    }
}