import React, { useRef } from "react";
import { Link } from "react-router-dom";
import navlogo from "../../images/logos/navlogo_heartland-grooming-co.png";
import NavLinks from "./NavLinks";

export default function Nav() {
    const navbarCollapseRef = useRef<HTMLDivElement>(null);

    return (
        <nav className="navbar navbar-expand-lg navbar-dark secondary-background">
            <div className="container px-5 navbar-inside-div justify-content-between">
                <div className="row justify-content-center align-content-center align-items-center">
                    <div className="col-auto justify-content-left">
                        <Link to="/">
                            <img
                                src={navlogo}
                                className="nav-logo"
                                alt="Heartland Supply Co Navigation Logo"
                            />
                        </Link>
                    </div>

                    <div className="col-auto justify-content-right">
                        <button
                            className="navbar-toggler justify-self-right"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>
                </div>
                <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                    ref={navbarCollapseRef}
                >
                    <NavLinks navbarCollapseRef={navbarCollapseRef} />
                </div>
            </div>
        </nav>
    );
}
