import React from 'react';
import spinner from '../images/logos/mustache-spinner.png';

const LoadingScreen = ({ isVisible = true }) => {
  return (
    <div className={`loading-screen ${isVisible ? '' : 'hide'}`}>
      <img src={spinner} alt="Loading..." className="spinner-image" />
    </div>
  );
};

export default LoadingScreen;
